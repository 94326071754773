import { validationMessage } from 'vuelidate-messages';// https://gitlab.com/rhythnic/vuelidate-messages#readme
import { mapGetters } from 'vuex';
// Validation Messages are stored in `panel/src/locales/en.json` & `panel/src/locales/sr.json`
/* eslint func-names: 0 */
/* eslint object-shorthand: 0 */
const formMessages = {
  required: function () {
    return this.$t('validation.required');
  },
  youMustSelectAtLeastOneRule: function () {
    return this.$t('validation.youMustSelectAtLeastOneRule');
  },
  email: function () {
    return this.$t('validation.email');
  },
  txtMinLen: function ({ $params }) {
    return this.$tc('validation.txtMinLen', $params.txtMinLen.min);
  },
  minValue: function ({ $params }) {
    return this.$tc('validation.minValue', $params.minValue.min);
  },
  maxValue: function ({ $params }) {
    return this.$tc('validation.maxValue', $params.maxValue.max);
  },
  between: function ({ $params }) {
    return this.$t('validation.between', { min: $params.between.min, max: $params.between.max });
  },
  minLength: function ({ $params }) {
    return this.$tc('validation.minLength', $params.minLength.min);
  },
  maxLength: function ({ $params }) {
    return this.$tc('validation.maxLength', $params.maxLength.max);
  },
  txtSameAsPassword: function () {
    return this.$tc('validation.txtSameAsPassword');
  },
  validPhoneNumber: function () {
    return this.$tc('validation.validPhoneNumber');
  },
};
// For Not TRANSLATABLE
// const formMessages = {
//   required: () => 'Required',
//   txtMinLen: ({ $params }) => `Must be at least ${$params.txtMinLen.min} characters...`,
// };

export default {
  computed: {
    ...mapGetters(['errorsBackend', 'hasValidationErrors']),
  },
  methods: {
    validationMsg: validationMessage(formMessages),
    firstError(fieldNameDeep) {
      const fields = fieldNameDeep.split('.');
      const field = fields.reduce((o, i) => o[i], this.$v);
      if (!field) {
        console.warn('No Field');
        // console.log(fieldNameDeep);
        return this.errorsBackend[fieldNameDeep];
      }
      /* eslint dot-notation: ["error", { "allowPattern": "^[a-z]+(_[a-z]+)+$" }] */
      const msg = this.validationMsg(field);
      if (msg) {
        return msg;
      }
      fields.shift();
      const filedNameLast = fields.join('.');
      if (this.errorsBackend
        && this.errorsBackend[filedNameLast]
        && this.errorsBackend[filedNameLast][0]) {
        return this.errorsBackend[filedNameLast][0];
      }
      return undefined;
    },
    collectErrorMessages() {
      const errors = [];
      Object.keys(this.$v.form.$params).forEach((key) => {
        if (this.$v.form[key].$invalid) {
          const a = this.firstError(`form.${key}`);
          errors.push(`${key}: ${a}`);
        }
      });
      return errors;
    },
  },
};
